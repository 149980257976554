import { css } from 'styled-components';

import { Margin, Maybe, MultipleDefaultContentPositions } from '../types/middleware-types';

export interface MarginWrapperProps {
  $backgroundColour?: Maybe<string>,
  $marginMobile: Margin,
  $marginDesktop: Margin
}

interface FullMediaWrapperProps extends MarginWrapperProps {
  $contentDefaultPositionMobile: MultipleDefaultContentPositions,
  $contentDefaultPositionDesktop: MultipleDefaultContentPositions
}

export const MarginWrapper = css<MarginWrapperProps>`
  background-color: ${({ $backgroundColour }) => $backgroundColour || 'transparent'};
  margin: ${({ $marginMobile }) =>
    `${$marginMobile?.topPixels ?? 0}px ${$marginMobile?.rightPixels ?? 0}px ${$marginMobile?.bottomPixels ?? 0}px ${$marginMobile?.leftPixels ?? 0}px`};

  @media ${({ theme }) => theme.devices.large} {
    margin: ${({ $marginDesktop }) =>
    `${$marginDesktop?.topPixels ?? 0}px ${$marginDesktop?.rightPixels ?? 0}px ${$marginDesktop?.bottomPixels ?? 0}px ${$marginDesktop?.leftPixels ?? 0}px`};
  }
`;

export const FullMediaWrapper = css<FullMediaWrapperProps>`
  ${MarginWrapper};
  flex-direction: ${({ $contentDefaultPositionMobile }) =>
  $contentDefaultPositionMobile === 'BELOW' ? 'column' : 'column-reverse'};

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: ${({ $contentDefaultPositionDesktop }) =>
    $contentDefaultPositionDesktop === 'BELOW' ? 'column' : 'column-reverse'};
  }
`;
