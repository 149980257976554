/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import { GlobalAnimationStyleObject } from '../../../sites/animations';
import MediaElement from '../../basic/MediaElement';
import { CustomMedia } from '../../types/custom-types';
import {
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockAnimationMediaCarousel,
} from '../../types/middleware-types';
import { CurrentPanel } from '../CMSModules/CelebrityStyleCarousel/CelebrityCarousel.types';
import ScreenReaderOnlyText from '../ScreenReaderOnlyText';
import {
  StyledCelebrityCardAsSeenOnCelebrity,
  StyledCelebrityCardAsSeenOnContainer,
  StyledCelebrityCardAsSeenOnTitle,
  StyledCelebrityCardButton,
  StyledCelebrityCardContainer,
  StyledCelebrityCardImageContainer,
  StyledCelebrityCardPanelContentWrapper,
  StyledCelebrityCardPanelImageWrapper,
  StyledCelebrityCardPanelWrapper,
} from './CelebrityCardStyles';

interface CelebrityCardStructureProps {
  largeImage: CustomMedia;
  smallImage: CustomMedia;
  contentHtml: string;
  title: string;
  brand: string;
  celebrity: string;
  shownPanel: boolean;
  showPanel: (shown: boolean) => void;
  currentPanel: CurrentPanel;
  setPanelState: (index: number, open: boolean) => void;
  cardIndex: number;
  showOnMobile: boolean;
  isMobileOrTablet: boolean;
  isDesktop: boolean;
  callToActionText?: string;
  callToActionUrl: string;
  pageName: string | null;
  animationType?: ContentBlockAnimationCelebrityStyleCarousel | ContentBlockAnimationMediaCarousel;
  animate?: boolean;
  animationStyle?: GlobalAnimationStyleObject;
  showBrandName: boolean;
  engs12817RemoveAsSeenOnHover: boolean;
  useReactRoutingLinks: boolean;
}

const CelebrityCardStructure: FC<CelebrityCardStructureProps> = ({
  largeImage,
  smallImage,
  contentHtml,
  title,
  brand,
  celebrity,
  shownPanel,
  showPanel,
  currentPanel,
  setPanelState,
  cardIndex,
  showOnMobile,
  isMobileOrTablet,
  isDesktop,
  callToActionText,
  callToActionUrl,
  pageName,
  animationType,
  animate,
  animationStyle,
  showBrandName,
  engs12817RemoveAsSeenOnHover,
  useReactRoutingLinks,
}) => (
  <StyledCelebrityCardContainer data-hookid={`${pageName}CelebrityCard`}>
    <StyledCelebrityCardImageContainer
      data-hookid={`${pageName}CelebrityCardImageContainer`}
      onClickCapture={() =>
        isMobileOrTablet &&
        !engs12817RemoveAsSeenOnHover &&
        setPanelState(cardIndex, !(shownPanel && cardIndex === currentPanel.index))}
      onMouseEnter={() => isDesktop && !engs12817RemoveAsSeenOnHover && setPanelState(cardIndex, true)}
      onMouseLeave={() => isDesktop && !engs12817RemoveAsSeenOnHover && showPanel(false)}
      $animationType={animationType}
      $animate={animate ?? false}
      $animationStyle={animationStyle}
    >
      <MediaElement {...largeImage} sizes="(max-width: 1024px) 100vw, 30vw" />
      {!engs12817RemoveAsSeenOnHover && (
        <StyledCelebrityCardPanelWrapper
          $showOnMobile={showOnMobile}
          $active={shownPanel && cardIndex === currentPanel.index}
        >
          <StyledCelebrityCardButton
            data-hookid={`${pageName}CelebrityCardButton`}
            type="button"
            onClick={() => showPanel(false)}
          >
            <FontAwesomeIcon icon={faTimes as IconProp} />
            <ScreenReaderOnlyText text="Close details" />
          </StyledCelebrityCardButton>
          <StyledCelebrityCardPanelContentWrapper>
            <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
          </StyledCelebrityCardPanelContentWrapper>
          {useReactRoutingLinks ? (
            <Link href={callToActionUrl} passHref legacyBehavior>
              <StyledCelebrityCardPanelImageWrapper>
                <p>{title}</p>
                {showBrandName && <p>{brand}</p>}
                {smallImage && <MediaElement {...smallImage} sizes="(max-width: 1024px) 60vw, 20vw" />}
                <span>{callToActionText}</span>
              </StyledCelebrityCardPanelImageWrapper>
            </Link>
          ) : (
            <StyledCelebrityCardPanelImageWrapper href={callToActionUrl}>
              <p>{title}</p>
              {showBrandName && <p>{brand}</p>}
              {smallImage && <MediaElement {...smallImage} sizes="(max-width: 1024px) 60vw, 20vw" />}
              <span>{callToActionText}</span>
            </StyledCelebrityCardPanelImageWrapper>
          )}
        </StyledCelebrityCardPanelWrapper>
      )}
    </StyledCelebrityCardImageContainer>
    <StyledCelebrityCardAsSeenOnContainer>
      <StyledCelebrityCardAsSeenOnTitle>As Seen On</StyledCelebrityCardAsSeenOnTitle>
      <StyledCelebrityCardAsSeenOnCelebrity>{celebrity}</StyledCelebrityCardAsSeenOnCelebrity>
      {!engs12817RemoveAsSeenOnHover && (
        <button
          type="button"
          onClick={() => setPanelState(cardIndex, !(shownPanel && cardIndex === currentPanel.index))}
        >
          <FontAwesomeIcon icon={shownPanel && cardIndex === currentPanel.index ? faMinus as IconProp : faPlus as IconProp} />
          <ScreenReaderOnlyText text="Show details" />
        </button>
      )}
    </StyledCelebrityCardAsSeenOnContainer>
  </StyledCelebrityCardContainer>
);

export default CelebrityCardStructure;
