import styled from 'styled-components';

import { GlobalAnimationStyleObject } from '../../../sites/animations';
import {
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockAnimationMediaCarousel,
} from '../../types/middleware-types';

interface StyledCelebrityCardImageContainerProps {
  $animate: boolean;
  $animationType?: ContentBlockAnimationCelebrityStyleCarousel | ContentBlockAnimationMediaCarousel;
  $animationStyle?: GlobalAnimationStyleObject;
}

interface StyledCelebrityCardPanelWrapperProps {
  $showOnMobile: boolean;
  $active: boolean;
}

export const StyledCelebrityCardContainer = styled.div`
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.celebrity.card.color};
  overflow-x: hidden;
  position: relative;
`;

export const StyledCelebrityCardImageContainer = styled.div<StyledCelebrityCardImageContainerProps>`
  position: relative;
  width: 100%;
  ${({ $animationType, theme }) =>
  $animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE'
    ? `
      transform: translateY(-100%);
      background: ${theme.vars.white};`
    : ''};
  ${({ $animate, $animationType, $animationStyle }) => {
      if ($animate) {
        if ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') {
          return $animationStyle?.scrollDown;
        }
      }
      return null;
    }}

  img {
    width: 100%;
    ${({ $animationType }) =>
      $animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE' ? 'opacity: 0' : ''};
    ${({ $animate, $animationType, $animationStyle }) => {
        if ($animate) {
          if ($animationType === 'MOVEFADE' || $animationType === 'BLOCKMOVEFADE') {
            return $animationStyle?.fadeIn;
          }
        }
        return null;
      }}
  }
`;

export const StyledCelebrityCardPanelWrapper = styled.div<StyledCelebrityCardPanelWrapperProps>`
  display: ${({ $showOnMobile }) => ($showOnMobile ? 'flex' : 'none')};
  z-index: ${({ $active }) => ($active ? '1' : '0')};
  background: ${({ theme }) => theme.vars.white};
  box-sizing: border-box;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3.2rem;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: calc(100% - 6.4rem);
  transform: ${({ $active }) => ($active ? 'translateX(6.4rem)' : 'translateX(calc(100% + 6.4rem))')};
  transition: transform 600ms ease-in;

  @media ${({ theme }) => theme.devices.medium} {
    display: flex;
  }
`;

export const StyledCelebrityCardPanelContentWrapper = styled.div`
  padding-bottom: 3.2rem;
  ${({ theme }) => theme.types.labelSmallStyle}
  margin: 0;
  text-align: left;

  p {
    margin: 0;
  }
`;

export const StyledCelebrityCardPanelImageWrapper = styled.a`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.types[theme.celebrity.card.color]};
  text-decoration: none !important;

  p {
    ${({ theme }) => theme.types[theme.celebrity.card.shoeName]}
    text-decoration: none;
    margin: 0.4rem;
    color: inherit;
    text-transform: none;
  }

  p:nth-child(2) {
    ${({ theme }) => theme.types[theme.celebrity.card.brandName]}
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  span {
    ${({ theme }) => theme.types.tertiaryLinkStyles}
    font-weight: 400;
    text-transform: capitalize;
    text-decoration: underline;
    color: inherit;
  }
`;

export const StyledCelebrityCardAsSeenOnContainer = styled.div`
  margin: 0.4rem 1.6rem 0;
  font-size: ${({ theme }) => theme.vars.sizeM};
  line-height: ${({ theme }) => theme.vars.sizeL};
  color: inherit;

  @media ${({ theme }) => theme.devices.large} {
    margin-top: 1.3rem;
  }

  button {
    border: none;
    background: transparent;
    padding: 0;
    font-size: ${({ theme }) => theme.vars.sizeM};
    color: ${({ theme }) => theme.celebrity.card.color};
    margin: 0.4rem 0.8rem 0.8rem 0;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      border: none;
      background-color: transparent;
      outline: none;
    }

    @media ${({ theme }) => theme.devices.large} {
      margin-top: 1rem;
    }
  }

  p {
    @media ${({ theme }) => theme.devices.large} {
      padding: 0.2rem 0 0;
    }
  }
`;

export const StyledCelebrityCardAsSeenOnTitle = styled.p`
  ${({ theme }) => theme.types[theme.celebrity.card.asSeenOnTitle]};
  text-transform: ${({ theme }) => theme.celebrity.card.asSeenOnTransform};
  padding: 1rem 0;
  margin: 0;

  @media ${({ theme }) => theme.devices.smallOnly} {
    padding: 0.5rem 0 0;
    margin: 0;
  }
`;

export const StyledCelebrityCardAsSeenOnCelebrity = styled.p`
  ${({ theme }) => theme.types[theme.celebrity.card.celebrity]};
  padding: 1rem 0;
  margin: 0;

  @media ${({ theme }) => theme.devices.smallOnly} {
    padding: 0.5rem 0 0;
    margin: 0;
  }
`;

export const StyledCelebrityCardButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: ${({ theme }) => theme.vars.sizeXL};
  width: ${({ theme }) => theme.vars.sizeXL};
  font-size: ${({ theme }) => theme.vars.sizeXL};
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0.8rem 0.8rem 0.8rem 0;
  cursor: pointer;
  color: ${({ theme }) => theme.celebrity.card.color};

  &:hover,
  &:focus,
  &:active {
    border: none;
    background-color: transparent;
    outline: none;
  }
`;
