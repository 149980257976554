import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { GlobalAnimationsStyle } from '../../../sites/animations';
import { selectShowBrandName, selectToggles } from '../../features/static';
import { CustomMedia } from '../../types/custom-types';
import {
  CelebrityStyleCarouselItem,
  ContentBlockAnimationCelebrityStyleCarousel,
  ContentBlockAnimationMediaCarousel,
} from '../../types/middleware-types';
import useMobileWatcher from '../../utils/customHooks/useMobileWatcher';
import usePageName from '../../utils/customHooks/usePageName';
import cleanHtml from '../../utils/formatters/cleanHtml';
import parseCMSMediaProp from '../../utils/formatters/parseCMSMediaProp';
import { normaliseImageSrc } from '../../utils/helpers/imageLoader';
import { CurrentPanel } from '../CMSModules/CelebrityStyleCarousel/CelebrityCarousel.types';
import CelebrityCardStructure from './CelebrityCardStructure';

export interface CelebrityCardProps {
  data: CelebrityStyleCarouselItem;
  showOnMobile: boolean;
  shownPanel: boolean;
  showPanel: (shown: boolean) => void;
  currentPanel: CurrentPanel;
  setPanelState: (index: number, open: boolean) => void;
  cardIndex: number;
  itemCallToActionText?: string;
  animationType?: ContentBlockAnimationCelebrityStyleCarousel | ContentBlockAnimationMediaCarousel;
  animate?: boolean;
  animationStyle?: GlobalAnimationsStyle;
  index?: number;
}

const CelebrityCard: FC<CelebrityCardProps> = ({
  data,
  showOnMobile,
  shownPanel,
  showPanel,
  currentPanel,
  setPanelState,
  cardIndex,
  itemCallToActionText,
  animationType,
  animate,
  animationStyle,
  index = 0,
}) => {
  const { name, image, contentHtml, product } = data;
  const { pageName } = usePageName();
  const { engs12817RemoveAsSeenOnHover, engs14345ReactRoutingATagsToLinks } = useSelector(selectToggles);

  const showBrandName = useSelector(selectShowBrandName);

  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useMobileWatcher(
    ['mobile', 'tablet', 'desktop'],
    theme.vars,
  );
  const isMobileOrTablet = isMobile || isTablet;

  const animStyle = animationStyle ? animationStyle(index * 200) : {};

  const smallImage: CustomMedia = {
    mediaList: {
      mobile: { src: normaliseImageSrc(product?.media?.productImages[0]?.url), width: 200, height: 200 },
      tablet: { src: normaliseImageSrc(product?.media?.productImages[0]?.url), width: 200, height: 200 },
      desktop: { src: normaliseImageSrc(product?.media?.productImages[0]?.url), width: 200, height: 200 },
    },
    defaultImageUrl: normaliseImageSrc(product?.media?.productImages[0]?.url),
    alt: product?.media?.productImages[0]?.alt || product?.name,
    type: 'next-image',
  };

  const componentProps = {
    largeImage: parseCMSMediaProp(image),
    smallImage: product?.media?.productImages[0] ? smallImage : null,
    title: product.name,
    brand: product.brand,
    contentHtml: cleanHtml(contentHtml),
    celebrity: name,
    callToActionUrl: product.url,
    callToActionText: itemCallToActionText,
    showOnMobile,
    shownPanel,
    showPanel,
    currentPanel,
    setPanelState,
    cardIndex,
    isMobileOrTablet: !!isMobileOrTablet,
    isDesktop: !!isDesktop,
    pageName,
    animationType,
    animate,
    animationStyle: animStyle,
    showBrandName,
    engs12817RemoveAsSeenOnHover,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
  };

  return <CelebrityCardStructure {...componentProps} />;
};

export default CelebrityCard;
